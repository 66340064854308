<template>
  <div>
    <v-card-title class="section-title">
      Natureza de Operação
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col v-if="!!local.description" cols="12" md="6">
          <v-text-field
            outlined
            readonly
            :disabled="!readonly"
            hide-details
            :value="local.description"
          />
        </v-col>

        <template v-else-if="!readonly">
          <v-col cols="12" md="3" sm="6">
            <v-select
              outlined
              required
              hide-details
              return-object
              label="Tipo de Nota Fiscal"
              :items="types"
              :value="local.type"
              @input="typeChanged"
            />
          </v-col>

          <template v-if="local.type">
            <v-col
              cols="12"
              :md="local.type.value === 'compra' ? 4 : 5"
              sm="6"
              v-if="local.type.reasons"
            >
              <v-select
                outlined
                required
                clearable
                hide-details
                return-object
                :label="'Motivo da ' + local.type.text"
                :items="local.type.reasons"
                :value="local.reason"
                @input="updateValueKey('reason', $event)"
              />
            </v-col>

            <v-col cols="12" md="3" sm="6" v-if="showDirections">
              <v-select
                outlined
                required
                return-object
                hide-details="auto"
                label="Operação"
                :items="local.type.directions"
                :rules="[notEmptyRule]"
                :value="local.direction"
                @input="updateValueKey('direction', $event)"
              />
            </v-col>
          </template>
        </template>

        <v-col
          v-if="showReferenceDocument"
          cols="12"
          :md="local.type.value === 'compra' ? 5 : 6"
        >
          <mask-text-field
            outlined
            required
            hide-details
            label="Chave da Nota Fiscal"
            mask="#### #### #### #### #### #### #### #### #### #### ####"
            :readonly="readonly"
            :value="referenceDocument"
            @input="$emit('update:reference-document', $event)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3" sm="6">
          <v-text-field
            v-if="readonly"
            outlined
            readonly
            hide-details
            label="Presença do consumidor"
            :value="pointOfSaleLabel"
          />

          <v-select
            v-else
            outlined
            required
            hide-details
            label="Presença do consumidor"
            :items="pointOfSales"
            :rules="[notEmptyRule]"
            :value="pointOfSale"
            @input="$emit('update:point-of-sale', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import UpdateModel from "@/mixins/UpdateModel";
import Rules from "@/mixins/Rules";
import MaskTextField from "@/components/MaskTextField";

export default {
  name: "NotaFiscalOperation",
  mixins: [UpdateModel, Rules],
  components: {
    MaskTextField,
  },
  props: {
    readonly: Boolean,
    value: Object,
    referenceDocument: String,
    pointOfSale: String,
  },
  computed: {
    local() {
      return this.value || {};
    },
    type() {
      const { type: docType } = this.local;
      if (!docType) return;

      return this.types.find((v) => v.value === docType.value);
    },
    reason() {
      if (!this.type) return;

      const { reason: docReason } = this.local;
      if (!docReason) return;

      return this.type.reasons.find((v) => v.value === docReason.value);
    },
    showReferenceDocument() {
      if (this.referenceDocument) return true;
      if (!this.type) return false;
      return this.type.referenceDocument || this.reason?.referenceDocument;
    },
    showDirections() {
      if (!this.type) return false;
      if (
        this.type.value === "remessa" &&
        this.reason?.value === "transferencia"
      ) {
        return false;
      }
      return !!this.type.directions;
    },
    types() {
      return [
        {
          text: "Venda",
          value: "venda",
          reasons: [
            { text: "À Ordem", value: "a_ordem" },
            { text: "Consignada", value: "consignada" },
            { text: "Entrega Futura", value: "entrega_futura" },
            { text: "Industrialização", value: "industrializacao" },
          ],
        },
        {
          text: "Compra",
          value: "compra",
          reasons: [
            { text: "Ativo imobilizado", value: "ativo_imobilizado" },
            { text: "Mercadorias", value: "mercadorias" },
            {
              text: "Produtor rural",
              value: "produtor_rural",
              referenceDocument: true,
            },
            { text: "Uso e consumo", value: "uso_e_consumo" },
          ],
        },
        {
          text: "Estorno",
          value: "estorno",
          directions: [
            { text: "Compra", value: "outgoing" },
            { text: "Venda", value: "incoming" },
          ],
          referenceDocument: true,
        },
        {
          text: "Devolução",
          value: "devolucao",
          directions: [
            { text: "Compra", value: "outgoing" },
            { text: "Venda", value: "incoming" },
          ],
          referenceDocument: true,
        },
        {
          text: "Remessa",
          value: "remessa",
          reasons: [
            { text: "Amostra grátis", value: "amostra_gratis" },
            {
              text: "Ativo imobilizado para uso fora do estabelecimento",
              value: "ativo_imobilizado_uso_fora",
            },
            { text: "Comodato" },
            { text: "Conserto" },
            { text: "Consignação" },
            { text: "Conta e ordem de terceiros" },
            { text: "Demonstração", value: "demonstracao" },
            { text: "Entrega futura" },
            { text: "Exposição ou feira" },
            { text: "Industrialização", value: "industrialização" },
            { text: "Vendas fora do estabelecimento" },
            { text: "Transferência entre filiais", value: "transferencia" },
            { text: "Outras saídas", value: "outras_saidas" },
          ],
          directions: [
            { text: "Entrada", value: "incoming" },
            { text: "Saída", value: "outgoing" },
          ],
        },
        {
          text: "Retorno",
          value: "retorno",
          reasons: [
            {
              text: "Ativo imobilizado remetido para uso fora",
              value: "ativo_imobilizado_uso_fora",
            },
            { text: "Comodato" },
            { text: "Conserto" },
            { text: "Consignação" },
            { text: "Demonstração" },
            { text: "Exposição ou feira" },
            { text: "Industrialização" },
            { text: "Industrialização não aplicada" },
            { text: "Outras entradas", value: "outras_entradas" },
            { text: "Vendas fora do estabelecimento" },
          ],
          directions: [
            { text: "Entrada", value: "incoming" },
            { text: "Saída", value: "outgoing" },
          ],
        },
      ];
    },
    pointOfSaleLabel() {
      const pos = this.pointOfSale || "store";
      const res = this.pointOfSales.find((v) => v.value === pos);
      return res?.text || pos;
    },
    pointOfSales() {
      return [
        {
          text: "Loja",
          value: "store",
        },
        {
          text: "Internet",
          value: "internet",
        },
        {
          text: "Marketplace online",
          value: "marketplace",
        },
        {
          text: "Telefone",
          value: "phone",
        },
        {
          text: "Fora do estabelecimento",
          value: "external",
        },
        {
          text: "Operação não presencial",
          value: "other",
        },
      ];
    },
  },
  methods: {
    typeChanged(v) {
      this.updateValueObject({
        type: v,
        reason: null,
        direction: null,
      });
      this.$emit("update:reference-document", "");
    },
  },
  created() {
    if (this.operation) return;

    const { tipo, motivo } = this.$route.query;

    let type = this.types.find((type) => type.value === tipo);
    this.updateValueKey("type", type);

    if (motivo && type && type.reasons) {
      this.$nextTick().then(() => {
        let reason = type.reasons.find((reason) => reason.value === motivo);
        if (reason) {
          this.updateValueKey("reason", reason);
        }
      });
    }
  },
};
</script>
