<template>
  <div>
    <v-card-title class="section-title">
      Dados de Entrega
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col class="pt-0">
          <v-radio-group
            row
            hide-details
            :value="local.type"
            @change="updateValueKey('type', $event)"
          >
            <v-radio
              v-show="!readonly || local.type === 'no_shipping'"
              label="Não haverá frete"
              value="no_shipping"
            />
            <v-radio
              v-show="!readonly || local.type === 'collect'"
              label="Retirada em outro local"
              value="collect"
            />
            <v-radio
              v-show="!readonly || local.type === 'issuer'"
              label="Transporte próprio"
              value="issuer"
            />
            <v-radio
              v-show="!readonly || local.type === 'third_party_cif'"
              label="Utilizarei uma transportadora"
              value="third_party_cif"
            />
          </v-radio-group>
        </v-col>
      </v-row>

      <nf-address
        v-if="showAddress"
        outlined
        hide-details="auto"
        :readonly="readonly"
        street-placeholder="Endereço para retirada"
        :value="local.address"
        @input="updateValueKey('address', $event)"
      />

      <v-row v-if="showCourier">
        <v-col cols="12">
          <v-card outlined>
            <v-card-title>
              <span class="secondary--text text--lighten-2 text-button">
                Dados da Transportadora
              </span>

              <span v-if="!readonly" class="caption">
                <span class="px-1">&dash;</span>
                <a
                  class="text-decoration-underline"
                  style="letter-spacing: normal !important"
                  @click="changeCourierType"
                  v-text="
                    isCourierCompany
                      ? 'Transportadora é pessoa física'
                      : 'Transportadora é pessoa jurídica'
                  "
                />
              </span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="3">
                  <cpf-cnpj-field
                    outlined
                    hide-details
                    :type="value.courier.type"
                    :readonly="readonly"
                    :required="!readonly"
                    :value="local.courier.taxNumber"
                    @input="updateCourierKey('taxNumber', $event)"
                  />
                </v-col>

                <v-col cols="12" sm="3">
                  <v-text-field
                    outlined
                    hide-details
                    label="Transportadora"
                    placeholder="Nome da transportadora"
                    :readonly="readonly"
                    :required="!readonly"
                    :value="local.courier.name"
                    :rules="[notEmptyRule]"
                    @input="updateCourierKey('name', $event)"
                  />
                </v-col>

                <v-col v-if="isCourierCompany" cols="12" sm="6">
                  <state-tax-field
                    outlined
                    hide-details
                    :readonly="readonly"
                    :required="!readonly"
                    :value="local.courier.stateTaxNumber"
                    @input="updateCourierKey('stateTaxNumber', $event)"
                  />
                </v-col>
              </v-row>

              <nf-address
                outlined
                hide-details
                hide-additional
                street-placeholder="Endereço da transportadora"
                :readonly="readonly"
                :value="local.courier.address"
                @input="updateCourierKey('address', $event)"
              />

              <v-row>
                <v-col cols="12" md="3" sm="4">
                  <v-text-field
                    outlined
                    hide-details
                    label="RNTC do veículo"
                    placeholder="Número do RNTC (Opcional)"
                    :readonly="readonly"
                    :required="!readonly"
                    :value="local.courier.vehicle.rntc"
                    @input="updateCourierVehicleKey('rntc', $event)"
                  />
                </v-col>

                <v-col cols="12" md="2" sm="3">
                  <mask-text-field
                    outlined
                    hide-details
                    label="Placa do veículo"
                    mask="AAA ####"
                    :readonly="readonly"
                    :required="!readonly"
                    :value="local.courier.vehicle.plate"
                    :rules="[notEmptyRule]"
                    @input="updateCourierVehicleKey('plate', $event)"
                  />
                </v-col>

                <v-col cols="12" md="3" sm="4">
                  <state-field
                    outlined
                    hide-details
                    :readonly="readonly"
                    :required="!readonly"
                    :value="local.courier.vehicle.state_code"
                    :rules="[notEmptyRule]"
                    @input="updateCourierVehicleKey('state_code', $event)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <template v-if="showShipping">
        <v-row>
          <v-col cols="12" md="2" sm="3">
            <v-text-field
              outlined
              type="number"
              hide-details="auto"
              label="Quant. Volumes"
              :readonly="readonly"
              :required="!readonly"
              :value="local.packages"
              :rules="[notEmptyRule, minDecimalRule(0)]"
              @input="updateValueKey('packages', $event)"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-combobox
              outlined
              hide-details="auto"
              label="Tipo de Volume"
              :items="packageTypes"
              :readonly="readonly"
              :required="!readonly"
              :menu-props="{ closeOnContentClick: true }"
              :value="local.unit"
              :rules="[notEmptyRule]"
              @input="unitChanged"
            />
          </v-col>

          <v-col cols="12" md="2" sm="3">
            <number-field
              outlined
              hide-details="auto"
              label="Peso Líquido"
              suffix="KG"
              :precision="4"
              :readonly="readonly"
              :required="!readonly"
              :value="local.weight"
              :rules="[notEmptyRule, minDecimalRule(0, local.weight)]"
              @input="updateValueKey('weight', $event)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="2" sm="3">
            <number-field
              outlined
              hide-details
              label="Valor do Frete"
              :prefix="value.cost ? 'R$' : undefined"
              :readonly="readonly"
              :required="!readonly"
              :value="local.cost"
              @input="updateValueKey('cost', $event)"
            />
          </v-col>

          <v-col cols="auto">
            <v-switch
              label="Somar frete no total da Nota Fiscal"
              :readonly="readonly"
              :value="local.includeShippingCost"
              @change="updateValueKey('includeShippingCost', $event)"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </div>
</template>

<script>
import Rules from "@/mixins/Rules";
import UpdateModel from "@/mixins/UpdateModel";
import MaskTextField from "@/components/MaskTextField";
import NumberField from "@/components/NumberField";
import CpfCnpjField from "@/components/CpfCnpjField";
import StateTaxField from "@/components/StateTaxField";
import StateField from "@/components/StateField";
import NfAddress from "@/components/Address";

export default {
  name: "NotaFiscalShipping",
  mixins: [Rules, UpdateModel],
  components: {
    MaskTextField,
    NumberField,
    CpfCnpjField,
    StateTaxField,
    StateField,
    NfAddress,
  },
  props: {
    readonly: Boolean,
    value: Object,
  },
  data: () => ({
    initialPackageTypes: [
      { text: "Caixa", value: "CX" },
      { text: "Pacote", value: "PCT" },
      { text: "Unidade", value: "UN" },
    ],
  }),
  computed: {
    local() {
      return (
        this.value || {
          type: "no_shipping",
          courier: {
            type: "company",
          },
        }
      );
    },
    showAddress() {
      return this.local.type === "collect";
    },
    showShipping() {
      return ["issuer", "third_party_cif"].includes(this.local.type);
    },
    showCourier() {
      return this.local.type === "third_party_cif";
    },
    packageTypes() {
      if (this.local.unit) {
        const type = this.initialPackageTypes.find(
          (t) => t.text == this.local.unit || t.value == this.local.unit
        );
        if (!type) {
          return [...this.initialPackageTypes, this.local.unit];
        }
      }
      return this.initialPackageTypes;
    },
    isCourierCompany() {
      return this.local.courier.type === "company";
    },
  },
  watch: {
    "local.unit"(v) {
      if (!v.text) {
        const type = this.initialPackageTypes.find((t) => t.value == v.value);
        this.unitChanged(type || v.value);
      }
    },
  },
  methods: {
    unitChanged(v) {
      if (typeof v === "string") {
        v = {
          text: v,
          value: v,
        };
      }
      this.updateValueKey("unit", v);
    },
    packageTypeChanged(v) {
      this.packageType = v.value || v;
      this.updateValueKey("unit", {});
    },
    updateCourierKey(key, value) {
      const courier = this.updateObjectKey(this.local.courier, key, value);
      this.updateValueKey("courier", courier);
    },
    updateCourierVehicleKey(key, value) {
      const vehicle = this.updateObjectKey(
        this.local.courier.vehicle,
        key,
        value
      );
      this.updateCourierKey("vehicle", vehicle);
    },
    changeCourierType() {
      this.updateCourierKey(
        "type",
        this.isCourierCompany ? "person" : "company"
      );

      // Clear all fields related to the type
      // Note: Emiting together with type cause issues in the MaskTextField
      // because it changes the cpf/cnpj mask
      this.$nextTick().then(() => {
        const courier = this.updateObject(this.local.courier, {
          taxNumber: "",
          stateTaxNumber: null,
        });
        this.updateValueKey("courier", courier);
      });
    },
  },
  created() {
    if (!this.local.type) {
      this.updateValueKey("type", "no_shipping");
    }
  },
};
</script>
