<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <v-row>
      <v-col cols="12" class="py-0">
        <nota-fiscal-title
          title="Nota Fiscal de Produto"
          :authorized-at="authorizedAt"
          :back-to="{ name: 'nf-product-list' }"
          :issueDate="issueDate"
          :number="value.number"
          :serie="value.serie"
        />
      </v-col>

      <v-expand-transition>
        <banner
          :canceled="value.canceled"
          :loading="saving"
          :nf-type="value.model"
          :pdf-url="value.pdf_url"
          :recipient="recipient"
          :sefaz-id="value.sefaz_id"
          :value="banner"
          :xml-url="value.xml_url"
          @click:save="sendNfe"
        />
      </v-expand-transition>

      <v-col cols="12">
        <v-card outlined :loading="saving">
          <v-form ref="form">
            <operation
              :readonly="readonly"
              :reference-document.sync="referenceDocument"
              :point-of-sale.sync="value.point_of_sale"
              v-model="operation"
            />

            <recipient
              class="pt-0"
              :readonly="readonly"
              :label="recipientLabel"
              v-model="recipient"
            />

            <products
              ref="products"
              class="pt-0"
              :readonly="readonly"
              :fiscal-regime="companyFiscalRegime"
              :nfe-operation="nfeOperation"
              :interstate="interstate"
              :cfop-applications="cfopApplications"
              :total.sync="totalLines"
              v-model="products"
            />

            <shipping class="pt-0" :readonly="readonly" v-model="shipping" />

            <nfe-payments
              v-if="showPayments"
              class="pt-0"
              :readonly="readonly"
              :discount.sync="totalDiscount"
              :total="totalNf"
              v-model="payments"
            />

            <additional-data
              class="pt-0"
              :readonly="readonly"
              v-model="notes"
            />

            <total
              class="pt-0"
              :total-lines="totalLines"
              :total-discount="totalDiscount"
              :total-shipping="totalShipping"
              :tax-base-icms="taxBaseIcms"
              :total-icms="totalIcms"
              :tax-base-icms-st="taxBaseIcmsSt"
              :total-icms-st="totalIcmsSt"
              :total-ipi="totalIpi"
              :total-pis="totalPis"
              :total-cofins="totalCofins"
              :total="totalNf"
            />
          </v-form>

          <v-card-actions
            class="py-12 px-sm-8 justify-center justify-sm-space-between"
          >
            <v-btn outlined class="d-none d-sm-flex" @click="$router.back()">
              Voltar
            </v-btn>

            <div v-if="!readonly">
              <template v-if="!!value.id">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      tile
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="duplicateNfe"
                    >
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>

                  <span>Duplicar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      tile
                      color="error"
                      class="mr-5"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteNfe"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>

                  <span>Remover</span>
                </v-tooltip>
              </template>

              <v-btn large color="success" :loading="saving" @click="saveNfe">
                <v-icon left>mdi-content-save-outline</v-icon>
                Salvar
              </v-btn>
            </div>

            <div v-else>
              <v-btn outlined @click="duplicateNfe">
                <v-icon left>mdi-content-duplicate</v-icon>
                Duplicar
              </v-btn>

              <v-btn
                v-if="!value.canceled"
                class="ml-4"
                color="error"
                @click="showCancelDialog = true"
              >
                <v-icon left>mdi-cancel</v-icon>
                Cancelar
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <nota-fiscal-cancel
      :nf-id="value.id"
      :serie="value.serie"
      :number="value.number"
      v-model="showCancelDialog"
      @update:canceled="canceledUpdated"
    />
  </v-container>
</template>

<script>
import Nfe from "@/mixins/Nfe";
import GoTo from "@/mixins/GoTo";
import $store from "@/store";
import { HttpError } from "@/components/Error";
import NotaFiscalTitle from "@/components/NotaFiscal/Title";
import Banner from "@/components/NotaFiscal/Banner";
import Operation from "@/components/NotaFiscal/Operation";
import Recipient from "@/components/NotaFiscal/Recipient";
import Products from "@/components/NotaFiscal/Products";
import Shipping from "@/components/NotaFiscal/Shipping";
import NfePayments from "@/components/NotaFiscal/NfePayments";
import AdditionalData from "@/components/NotaFiscal/AdditionalData";
import Total from "@/components/NotaFiscal/Total";
import NotaFiscalCancel from "@/components/NotaFiscal/Cancel";

export default {
  name: "NotasFiscaisProductsView",
  metaInfo() {
    return {
      title: "Nova Nota Fiscal de Produto",
    };
  },
  mixins: [Nfe, GoTo],
  components: {
    NotaFiscalTitle,
    Banner,
    Operation,
    Recipient,
    Products,
    Shipping,
    NfePayments,
    AdditionalData,
    Total,
    NotaFiscalCancel,
  },
  data: () => ({
    saving: false,
    hasChanged: false,
    showCancelDialog: false,
    value: {
      model: "nfe",
      point_of_sale: "store",
    },

    // nfe fields
    operation: {
      type: "",
      reason: "",
      direction: "",
    },
    referenceDocument: "",
    recipient: {
      address: {},
    },
    products: [],
    shipping: {
      address: {},
      courier: {
        vehicle: {},
        address: {},
      },
    },
    payments: [],
    notes: {},
  }),
  computed: {
    readonly() {
      return !!this.value.sefaz_id;
    },
    banner() {
      const { id, status, sefaz_id: sefazId } = this.value;
      if (id) {
        if (status === "canceled") return "canceled";
        if (sefazId) return "sent";
        if (!this.hasChanged) return "send";
      }
      return "";
    },
    companyFiscalRegime() {
      return this.company?.fiscalRegime || "simple";
    },
    nfeOperation() {
      let op = [];
      if (this.operation.type) op.push(this.operation.type.value);
      if (this.operation.reason) op.push(this.operation.reason.value);
      if (this.operation.direction) op.push(this.operation.direction.value);
      return op.join("_");
    },
    recipientLabel() {
      const type = this.operation.type?.value;
      const direction = this.operation.direction?.value;
      switch (type) {
        case "remessa":
          return "Destinatário";
        case "compra":
          return "Fornecedor";
        case "devolucao":
        case "estorno":
          if (direction === "outgoing") return "Fornecedor";
      }
      return "Cliente";
    },
    interstate() {
      if (!this.recipient?.address?.city?.state) return false;
      return (
        this.company?.address?.city?.state !== this.recipient.address.city.state
      );
    },
    showPayments() {
      if (!this.operation.type?.value) return true;
      return !["remessa", "estorno", "devolucao"].includes(
        this.operation.type.value
      );
    },
    cfopApplications() {
      let apps = ["nfe"];
      switch (this.operation.type?.value) {
        case "venda":
          apps.push("outgoing");
          break;
        case "compra":
          apps.push("incoming");
          break;
        case "devolucao":
          apps.push("return");
          break;
        case "estorno":
          if (this.operation.direction?.value) {
            apps.push(this.operation.direction.value);
          }
          break;
        case "remessa":
          switch (this.operation.reason?.value) {
            case "transferencia":
              apps.push("outgoing");
              break;
            default:
              apps.push("shipping");
          }
          break;
        case "retorno":
          apps.push("shipping_return");
          break;
      }
      return apps;
    },
  },
  watch: {
    nfe() {
      this.hasChanged = true;
    },
    company() {
      if (!this.value.id) return;

      this.$nextTick().then(() => {
        // when a new company is selected, go back to list
        this.$router.push({ name: "nf-product-list" });
      });
    },
    showPayments() {
      this.payments = [];
    },
    $route(v) {
      switch (v.name) {
        case "nf-product-new":
          this.loadNfe(this.value.id, true);
          break;
        case "nf-product-view":
          this.loadNfe(v.params.id);
          break;
      }
    },
  },
  methods: {
    validate() {
      const { form, products } = this.$refs;

      let errs;

      if (form.validate()) {
        const valid = (errs = products.validate()) === true;
        if (valid) {
          return true;
        }
      } else {
        this.notifyError("Alguns campos obrigatórios não foram preenchidos 😢");
        return false;
      }

      this.notifyError(
        "Encontramos alguns problemas ao tentar salvar a NF 😢<ul><li>" +
          errs.join("</li><li>") +
          "</li></ul>"
      );
      return false;
    },
    async saveNfe() {
      this.saving = true;
      if (!this.validate()) {
        this.saving = false;
        return;
      }

      let method,
        url = "/v1/nfe/documents";
      if (!this.value.id) {
        const { serie, number } = await this.getNextNfNumber();
        this.$set(this.value, "serie", serie);
        this.$set(this.value, "number", number);
        method = "post";
      } else {
        url += `/${this.value.id}`;
        method = "put";
      }

      try {
        const {
          data: { data: nfe },
        } = await this.$http[method](url, this.oldNfeFormat);

        if (method === "post") {
          this.$router.push({
            name: "nf-product-view",
            params: { id: nfe.id },
          });
        } else {
          await this.loadNfe(nfe.id);
          this.$nextTick().then(() => {
            this.goTo(0);
          });
        }

        this.notifySuccess("Nota Fiscal salva com sucesso 🎉");
        this.$nextTick().then(() => {
          this.hasChanged = false;
        });
      } catch (err) {
        if (err instanceof HttpError) {
          if (err.code === "nfe_already_sent") {
            await this.loadNfe(this.value.id);
            this.goTo(0);
            return;
          }
          if (err.field) {
            this.notifyError(`O campo "${err.field}" não é válido`);
            this.$sentry.captureException(err);
            return;
          }
        }

        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao salvar a Nota Fiscal 😢");
      } finally {
        this.saving = false;
      }
    },
    async sendNfe() {
      this.saving = true;
      try {
        await this.sendNf(this.value.id);
        this.goTo(0);
        this.notifySuccess("Nota Fiscal transmitida com sucesso 🎉");
      } catch (err) {
        if (err instanceof HttpError) {
          if (
            err.code === "invalid_nfe" ||
            err.code.startsWith("invalid_nfe_error_")
          ) {
            this.notifyError(err.msg, 12000);
            return;
          }
          this.$sentry.captureException(err);
          this.notifyError("Ocorreu um erro ao transmitir a NF-e 😢");
          return;
        }

        console.error(err);
        this.$sentry.captureException(err);
      } finally {
        this.saving = false;
        this.hasChanged = false;
      }
    },
    async deleteNfe() {
      const { id, serie, number } = this.value;

      const deleted = await this.deleteNf(id, serie, number);
      if (deleted) {
        this.$router.push({ name: "nf-product-list" });
      }
    },
    duplicateNfe() {
      this.$router.push({ name: "nf-product-new" });
    },
    async canceledUpdated(v) {
      this.$set(this.value, "canceled", v);
      await this.addProductTransactions({
        model: this.value.model,
        serie: this.value.serie,
        number: this.value.number,
        operationType: this._operationType,
        canceled: !!v,
        transactionDate: v.canceled_at,
        lines: this.products.map((line) => ({
          product_id: line.product?.id,
          quantity: line.quantity,
        })),
      });
      this.$nextTick().then(() => {
        this.goTo(0);
      });
    },
    async loadNfe(id, onlyData) {
      try {
        await this.getNf(id, onlyData);
      } catch (err) {
        this.$router.push({ name: "nf-product-list" });
      } finally {
        setTimeout(() => {
          this.hasChanged = false;
        }, 100);
      }
    },
  },
  mounted() {
    if (this.value.id) {
      let oldNFeFormat = this.convertNFe(this.value);
      this.loadNf(oldNFeFormat);
      setTimeout(() => {
        this.hasChanged = false;
      }, 200);
    }
  },
  async beforeRouteEnter(to, from, next) {
    const { id } = to.params;
    if (!id) {
      next();
      return;
    }

    try {
      let nfe = await $store.dispatch("nfes/get", id);
      next((vm) => {
        vm.value = nfe;
      });
    } catch (err) {
      next({ name: "nf-product-list" });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const { id } = to.params;
    if (!id) {
      next();
      return;
    }

    try {
      this.value = await this.$store.dispatch("nfes/get", id);
      next();
    } catch (err) {
      next({ name: "nf-product-list" });
    }
  },
};
</script>
