<template>
  <v-select
    item-text="name"
    item-value="id"
    :items="items"
    :label="label"
    :value="value"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StateField",
  props: {
    country: { type: String, default: "BR" },
    label: { type: String, default: "UF" },
    value: String,
  },
  computed: {
    items() {
      return this.countryStates(this.country).map((state) => {
        return {
          id: state.code,
          name: state.name,
        };
      });
    },
    ...mapGetters(["countryStates"]),
  },
  created() {
    this.$store.dispatch("fetchCountryStates", this.country);
  },
};
</script>
